export function match(param: string) {
	// 15 digit id
	// i.e. 4uyxfo9qa4t9ul5
	//
	// OR
	//
	// 15 digit id
	// a forward slash
	// any slug
	// i.e. 4uyxfo9qa4t9ul5/squamish-one-two-three
	return /^[a-z0-9]{15}$/.test(param) || /^[a-z0-9]{15}\/[a-z0-9-]+$/.test(param);
}
