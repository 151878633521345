import { env } from '$env/dynamic/public';
import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import posthog from 'posthog-js';

Sentry.init({
	dsn: env.PUBLIC_SENTRY_DSN || undefined, // when undefined, sentry doesnt init
	tracesSampleRate: 1.0,

	integrations: [new posthog.SentryIntegration(posthog, 'codex-kit', 4507224898535424)]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
