import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56')
];

export const server_loads = [0,2,3,4,5,6,9];

export const dictionary = {
		"/": [11],
		"/about": [32],
		"/auth": [33,[7]],
		"/auth/login": [~34,[7]],
		"/auth/password-reset": [~35,[7]],
		"/auth/password-reset/[token]": [~36,[7]],
		"/auth/register": [~37,[7]],
		"/auth/verify/[token]": [~38,[7]],
		"/contact": [~39],
		"/editor-demo": [40],
		"/faq": [41],
		"/(app)/guides": [12,[2]],
		"/(app)/guides/edit": [~31,[2]],
		"/(app)/guides/[...guideIdSlug=idSlug]/areas": [14,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/areas/edit/map": [~16,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/areas/edit/overview": [~17,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/areas/[...areaIdSlug=idSlug]": [15,[2,3,4]],
		"/(app)/guides/[...guideIdSlug=idSlug]/ethos": [18,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/ethos/edit": [~20,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/ethos/[...ethosIdSlug=idSlug]": [19,[2,3,5]],
		"/(app)/guides/[...guideIdSlug=idSlug]/gear": [~21,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/media": [~22,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/media/edit/image": [~23,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/media/edit/overview": [~24,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/notices/manage": [~26,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/notices/manage/edit": [~27,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/notices/[noticeId]": [~25,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/routes": [~28,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/routes/edit": [~30,[2,3]],
		"/(app)/guides/[...guideIdSlug=idSlug]/routes/[...routeIdSlug=idSlug]": [29,[2,3,6]],
		"/(app)/guides/[...guideIdSlug=idSlug]": [13,[2,3]],
		"/legal": [42,[8]],
		"/legal/license-agreement": [43,[8]],
		"/legal/privacy-policy": [44,[8]],
		"/legal/terms-of-service": [45,[8]],
		"/pricing": [~46],
		"/users/[userId]": [47,[9]],
		"/users/[userId]/edit": [~48,[9,10]],
		"/users/[userId]/edit/payouts": [~49,[9,10]],
		"/users/[userId]/edit/payouts/delete": [~50,[9,10]],
		"/users/[userId]/edit/payouts/manage": [~51,[9,10]],
		"/users/[userId]/edit/payouts/new": [~52,[9,10]],
		"/users/[userId]/edit/subscriptions": [~53,[9,10]],
		"/users/[userId]/edit/subscriptions/manage": [~55,[9,10]],
		"/users/[userId]/edit/subscriptions/success": [~56,[9,10]],
		"/users/[userId]/edit/subscriptions/[priceId]": [~54,[9,10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';